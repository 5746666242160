<template>
  <div class="feature events-feature">
    <HomeFeatureContentContainer>
      <img :src="`${useCdnUrl()}/assets-2024/events/events-logo.svg`" alt="Event" />
      <h2>Preview of the Crystal VIP Experience</h2>
      <p>
        A new tradition to celebrate the past with a timeless keepsake and to preview what the
        future will hold. Limited crystals from the current New Year's Eve Ball will be given to the
        public on December 19th starting at 11am in Times Square at the 46th - 47th Street Plaza.
      </p>
      <TheButton to="/events/ball-crystal-keepsake" class="events-callout">LEARN MORE</TheButton>
    </HomeFeatureContentContainer>

    <div class="feature-image-effect">
      <SplitImage
        :srcs="[
          `${useCdnUrl()}/assets-2024/events/events-poster-alt.jpg`,
          `${useCdnUrl()}/assets-2024/events/events-poster.jpg`,
        ]"
      />
    </div>

    <div
      class="feature-image-mobile"
      :style="{ 'background-image': `url(${useCdnUrl()}/assets-2024/events/events-poster.jpg)` }"
    >
      <img :src="`${useCdnUrl()}/assets-2024/events/events-logo-white.svg`" alt="Event" />
    </div>
  </div>
</template>

<style lang="scss">
.events-feature {
  .feature-content {
    background-color: var(--events-primary-color);

    .content-container {
      h2 {
        line-height: 1;
        margin: 0;
      }

      h2,
      p {
        color: black;
      }

      .events-callout {
        background-color: var(--events-complimentary-color);
        border-width: 0;

        &:hover {
          color: black;
        }
      }
    }
  }

  .feature-image-effect {
    .split-image {
      .split-slider {
        background-color: var(--events-primary-color) !important;
      }
    }
  }
}
</style>
